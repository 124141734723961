import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import auth from './modules/auth/auth.js';
import users from './modules/classes/users.js';
import categories from './modules/classes/categories.js';
import pubs from './modules/classes/pubs.js';
import orders from './modules/classes/orders.js';
import products from './modules/classes/products.js';

export default new Vuex.Store({
    modules : {
        auth,
        users,
        categories,
        pubs,
        orders,
        products,
    },
    plugins: [createPersistedState()]
});