import { fetchData, deleteData } from "@/components/utils/actions";
import { FETCH_DATA, DELETE_DATA } from "@/components/utils/mutations";

const state = {
  orders: [],
};

const getters = {
  getOrders: (state) => state.orders,
};

const actions = {
  async fetchOrders({ commit }) {
    const response = await fetchData("orders-list", commit, "FETCH_ORDERS");
    return response;
  },

  async deleteOrder({ commit }, id) {
    const response = await deleteData(
      "delete-order",
      id,
      commit,
      "DELETE_ORDER"
    );
    return response;
  },
};

const mutations = {
  FETCH_ORDERS(state, order) {
    FETCH_DATA(state, order, "orders");
  },

  DELETE_ORDER: (state, id) => {
    DELETE_DATA(state, id, "orders");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
