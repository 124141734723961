import exportMixin from "@/components/mixins/globals/exports/export";
import AXIOS from "@/components/config/AXIOS";
import users from "@/components/mixins/models/users";
export default {
  mixins: [exportMixin, users],

  watch: {
    selectAllOrders: function (value) {
      if (value) {
        this.ordersFilterByUser.forEach((pub) => {
          this.checked.push(pub.id);
        });
      } else {
        this.checked = [];
      }
    },
  },

  methods: {
    async loadData(url) {
      await AXIOS.get(url).then((response) => {
        this.order = response.data;
      });
    },

    async deleteOrders() {
      await AXIOS.delete("delete-all-orders/" + this.checked);
      await this.fetchOrders();

      this.checked = "";
      this.selectAllOrders = "";
    },

    updateOrderStatus(orderId, newStatus) {
      if (newStatus !== 1 && newStatus !== 2) {
        console.error("Valeur de statut invalide");
        return;
      }

      AXIOS
        .put(`/orders/${orderId}/status`, { status: newStatus })
        .then((response) => {
          if (response && response.data && response.data.message) {
            this.fetchOrders();
          } else {
            console.error("Réponse invalide");
          }
        })
        .catch((error) => {
          console.error("Échec de la mise à jour du statut  :", error);
        });
    },

    toggleOrderStatus(order) {
      const newStatus = order.status === 1 ? 2 : 1;
      this.updateOrderStatus(order.id, newStatus);
      this.successNote(`Statut de la commande N° ${order.code} modifié!`);
    },
  },

  computed: {
    orderList() {
      return this.fetchData(this.getOrders);
    },

    ordersFilterByUser() {
      return this.filterByAttribute(
        this.orderList,
        this.usersList,
        "user_id",
        this.user_query
      ).slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },

    sumTotal() {
      return this.sumOnAttribute(this.ordersFilterByUser, "total_amount");
    },

    validatedOrders() {
      return this.fetchDataByAttributeCompare(this.orderList, "status", "=", 2);
    },
    waitingOrders() {
      return this.fetchDataByAttributeCompare(this.orderList, "status", "=", 1);
    },

    sumTotalValidated() {
      return this.sumOnAttribute(this.validatedOrders, "total_amount");
    },
    sumTotalWaiting() {
      return this.sumOnAttribute(this.waitingOrders, "total_amount");
    },
  },
};
