import _mount from "@/components/mixins/globals/exports/_mountData";
export default {
  mixins: [_mount],
  async mounted() {
    try {
      await this.load();
    } catch (error) {
      console.log("Une erreur s'est produite lors du chargement des données");
    }
  },
};
