import exportMixin from '@/components/mixins/globals/exports/export';
import { useToastr } from "@/components/mixins/globals/toastr";
import AXIOS from "@/components/config/AXIOS";
// import Swal from 'sweetalert';

const toastr = useToastr();

export default {
    mixins: [exportMixin],

    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        async userLogin() {
            return await this.loginInterface(this.auth.phone, this.auth.password);
        },

        async registerUser() {
            await this.registerValidation();
        },

        async logOut() {
            this.logout(this.userAuth);
            this.$router.push("/");
        },

        resendEmail() {
            if (!this.verifyAt) {
                AXIOS.post(`/resend-confirmation-email/${this.authID}`);
                this.alert_resend = true;
            } else {
                this.alert_resend = false;
                this.infoNote("l'email est déja vérifié")
            }
        },

        checkExistingUser(email, phone) {
            return AXIOS.get('/check-existing-user', {
                params: {
                    email: email,
                    phone: phone
                }
            })
                .then(response => {
                    const message = response.data.message;
                    if (message === 'Un utilisateur avec les mêmes informations existe déjà') {
                        toastr.error(message);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(error => {
                    console.error(error);
                    return true;
                });
        },



        checkInactivity() {
            const currentTime = Date.now();
            const lastActivity = this.$store.getters.lastActivity;

            if (this.isAuthenticated && lastActivity && (currentTime - lastActivity) >= this.idleTime) {
                this.logOut();
            }
        },

        handleActivity() {
            this.resetInactivityTimer();
        },

        resetInactivityTimer() {
            this.$store.dispatch('resetInactivityTimer');
        },

        setLastActivity(time) {
            this.SET_LAST_ACTIVITY(time);
        },

        getIndicatif() {
            this.user.phone = this.indicatif;
        },
    },

    computed: {
        authID() {
            return this.userAuth.id;
        },

        verifToken() {
            return this.userAuth.email_verification_token;
        },
        verifyAt() {
            return this.userAuth.email_verified_at;
        },


        authName() {
            return this.userAuth.name;
        },

        authEmail() {
            return this.userAuth.email;
        },

        authRole() {
            return this.userAuth.role;
        },
        authStatus() {
            return this.userAuth.status;
        },

        isLoggedIn: function () {
            return this.isAuthenticated;
        },

        allCountries() {
            return this.fetchDataByQueryOnName(this.country_select, this.countries);
        }
    },

    mounted() {

        document.addEventListener('mousemove', this.handleActivity);
        document.addEventListener('keydown', this.handleActivity);
        document.addEventListener('click', this.handleActivity);

        setInterval(() => {
            this.checkInactivity();
        }, this.idleTime);
    },

    beforeDestroy() {
        document.removeEventListener('mousemove', this.handleActivity);
        document.removeEventListener('keydown', this.handleActivity);
        document.removeEventListener('click', this.handleActivity);
    }
}
