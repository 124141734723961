import icons from "../../../utils/icons";

export default {
  data() {
    return {
      icons,
      auth: {
        phone: "",
        password: "",
      },

      user: {
        name: "",
        email: "",
        phone: "",
        role: "",
        password: "",
        confirmed_password: "",
        profile: null,
        verificationCode: "",
      },

      category: {
        name: "",
        image: null,
      },

      pub: {
        title: "",
        image: null,
      },

      commission: {
        percent: "",
      },

      order: {},

      product: {
        name: "",
        price: "",
        quantity: "",
        discount: null,
        description: "",
        user_id: null,
        category_id: null,
        image: null,
        images: [],
      },

      countries: [
        { name: "Burkina", indicatif: 226 },
        { name: "Togo", indicatif: 228 },
        { name: "Ghana", indicatif: 223 },
        { name: "Cote d'Ivoir", indicatif: 225 },
      ],

      indicatif: "",

      image: null,
      images: [],
      selected_image: null,
      selected_images: [],
      imgUrl: "https://africkraaga.com/",
      // imgUrl: "http://127.0.0.1:8000",

      checked: [],
      selectAllUsers: false,
      selectAllCategories: false,
      selectAllPubs: false,
      selectAllOrders: false,
      selectAllProducts: false,

      q: "",
      role_filter: "",
      status_filter: "",
      user_query: "",
      search_by_cat: "",
      search_by_user: "",

      idleTime: 15 * 60 * 1000,

      pageSize: 5,
      currentPage: 1,

      showForm: 2,
      changePass: false,
      changeProfile: false,
      alert_resend: false,

      percent: null,
    };
  },
};
