<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-black row">
              <div class="card-header">
                <h3 class="card-title">Modifier catégorie</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                @submit.prevent="editCategory"
                enctype="multipart/form-data"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="input-group mb-3 col-md-6">
                      <input
                        required
                        v-model="category.name"
                        type="text"
                        class="form-control"
                        placeholder="Nom"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-user"></span>
                        </div>
                      </div>
                    </div>
                    <div class="input-group mb-3 col-md-6">
                      <label class="form-control" for="customFile">image</label>
                      <input
                        class="form-control"
                        name="image"
                        type="file"
                        id=""
                        ref="imageInput"
                        @change="saveImage"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-file"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="image-container d-flex justify-content-between">
                    <div class="image-wrapper">
                      <div class="input-group mb-3">
                        <img
                          :src="`${imgUrl + '/' + category.image}`"
                          class="old-image"
                          alt="Ancienne Image"
                        />
                      </div>
                    </div>
                    <div class="image-wrapper" v-if="image">
                      <div class="col-md-6" v-if="image">
                        <img
                          :src="selected_image"
                          class="new-image"
                          alt="Nouvelle Image sélectionnée"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-light">Valider</button>
                  <router-link
                    to="/categories"
                    type="submit"
                    class="btn btn-danger"
                    style="margin-left: 4px"
                  >
                    <i class="fas fa-arrow-right"></i>
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import categories from "@/components/mixins/models/categories";

export default {
  data() {
    return {};
  },

  mixins: [template, categories],
  methods: {
    loadCategory() {
      this.loadData(`show-category/${this.$route.params.id}`);
    },
  },

  mounted() {},

  computed: {},

  created() {
    return this.loadCategory();
  },
};
</script>
<style scoped>
.old-image {
  width: 200px;
  height: 200px;
}

.new-image {
  width: 200px;
  height: 200px;
}
</style>
