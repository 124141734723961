<template>
  <footer class="main-footer">
    <strong
      >Copyright © 2023
      <a href="https://www.switch-maker.com/" target="_blank" class="text-light"
        >Switch Maker</a
      >.</strong
    >
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Afrik-Raaga</b> 1.0
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
