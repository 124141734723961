import {
  fetchData,
  createData,
  updateDataWithImage,
  deleteData,
} from "@/components/utils/actions";
import {
  FETCH_DATA,
  NEW_DATA,
  UPDATE_DATA,
  DELETE_DATA,
} from "@/components/utils/mutations";

const state = {
  pubs: [],
};

const getters = {
  getPubs: (state) => state.pubs,
};

const actions = {
  async fetchPubs({ commit }) {
    await fetchData("pubs", commit, "FETCH_PUBS");
  },

  async newPub({ commit }, pub) {
    await createData("new-pub", pub, commit, "NEW_PUB");
  },

  async updatePub({ commit }, pub) {
    await updateDataWithImage("update-pub", pub.id, pub, {
      title: pub.title,
      image: pub.image,
    });
  },

  async deletePub({ commit }, id) {
    await deleteData("delete-pub", id, commit, "DELETE_PUB");
  },
};

const mutations = {
  FETCH_PUBS(state, pub) {
    FETCH_DATA(state, pub, "pubs");
  },

  NEW_PUB: (state, pub) => {
    NEW_DATA(state, pub, "pubs");
  },
  UPDATE_PUB: (state, updated) => {
    UPDATE_DATA(state, "pub", "pubs", updated);
  },

  DELETE_PUB: (state, id) => {
    DELETE_DATA(state, id, "pubs");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
