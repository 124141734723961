<template>
  <div>
    <horizontal/>
    <vertical/>
    <dashboard/>
    <Footer/>
  </div>
</template>
    
    <script>
import template from "@/components/mixins/globals/template";
export default {
  data() {
    return {};
  },

  mixins: [template],
  methods: {},

  mounted() {},

  computed: {},
};
</script>

    