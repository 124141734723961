import exportMixin from "@/components/mixins/globals/exports/export";
import AXIOS from "@/components/config/AXIOS";
export default {
  mixins: [exportMixin],

  watch: {
    selectAllCategories: function (value) {
      if (value) {
        this.categoriesFilterByTitle.forEach((category) => {
          this.checked.push(category.id);
        });
      } else {
        this.checked = [];
      }
    },
  },

  methods: {
    addCategory() {
      let formData = new FormData();
      formData.append("name", this.category.name);
      formData.append("image", this.image);

      this.newData(
        this.newCategorie,
        formData,
        "Catégorie ajoutée avec succes",
        "/categories"
      );
    },

    editCategory() {
      this.category.image = this.image;
      this.updateData(
        this.updateCategorie,
        this.category,
        "Categorie mise à jour",
        "/categories"
      );
    },

    async loadData(url) {
      await AXIOS.get(url).then((response) => {
        this.category = response.data;
        this.category.image = response.data.image;
      });
    },

    async deleteCategories() {
      await AXIOS.delete("delete-all-categories/" + this.checked);
      await this.fetchCategories();

      this.checked = "";
      this.selectAllCategories = "";
    },
  },

  computed: {
    categoryList() {
      return this.fetchData(this.getCategories);
    },

    categoriesFilterByTitle() {
      return this.fetchDataByQueryOnName(this.q, this.categoryList).slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
};
