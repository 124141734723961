<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-black row">
              <div class="card-header">
                <h3 class="card-title">Nouveau Produit</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form @submit.prevent="addProduct" enctype="multipart/form-data">
                <div class="card-body">
                  <div class="row">
                    <div class="input-group col-md-6">
                      <input
                        required
                        v-model="product.name"
                        type="text"
                        class="form-control"
                        placeholder="libelle *"
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        required
                        v-model="product.price"
                        type="number"
                        class="form-control"
                        placeholder="prix *"
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        required
                        v-model="product.quantity"
                        type="number"
                        class="form-control"
                        placeholder="quantité *"
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        v-model="product.discount"
                        type="number"
                        class="form-control"
                        placeholder="rabais *"
                      />
                    </div>
                    <div class="input-group mb-3 col-md-6">
                      <label class="form-control">Vendeur *</label>
                      <select
                        required
                        v-model="product.user_id"
                        class="form-control"
                      >
                        <option
                          class="input"
                          v-for="(user, i) in usersList"
                          :key="i"
                          :value="user.id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="input-group mb-3 col-md-6">
                      <label class="form-control">Catégorie *</label>
                      <select
                        required
                        v-model="product.category_id"
                        class="form-control"
                      >
                        <option
                          class="input"
                          v-for="(categorie, i) in categoryList"
                          :key="i"
                          :value="categorie.id"
                        >
                          {{ categorie.name }}
                        </option>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Présentation *</label>
                        <textarea
                        required
                          v-model="product.description"
                          class="form-control"
                          rows="3"
                          placeholder="presentation du produit"
                        ></textarea>
                      </div>
                    </div>

                    <div class="input-group mb-3 col-md-6">
                        <label class="mr-3">Images du produit *</label>
                      <input
                      required
                        class="form-control"
                        name="images"
                        type="file"
                        id=""
                        ref="imageInput"
                        @change="saveImages"
                        multiple
                      />
                    </div>
                  </div>

                  <div class="image-container d-flex justify-content-between">
                    <div class="image-container d-flex justify-content-between">
                      <div
                        v-for="(image, index) in selected_images"
                        :key="index"
                        class="image-wrapper"
                      >
                        <div class="input-group mb-3 d-flex">
                          <img
                            :src="image"
                            class="new-image mr-3"
                            alt="Image sélectionnée"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-light">Ajouter</button>
                  <router-link
                    to="/products"
                    type="submit"
                    class="btn btn-danger"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-right"></i
                  ></router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import categories from "@/components/mixins/models/categories";
import products from "@/components/mixins/models/products";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin, categories, products],
  methods: {},

  mounted() {},

  computed: {},

  created() {},
};
</script>
<style scoped>
.old-image {
  width: 200px;
  height: 200px;
}

.new-image {
  width: 200px;
  height: 200px;
}
</style>
