import exportMixin from "@/components/mixins/globals/exports/export";
import auth from "@/components/mixins/globals/auth/auth";
import categories from "@/components/mixins/models/categories";
import users from "@/components/mixins/models/users";
import AXIOS from "@/components/config/AXIOS";
export default {
  mixins: [exportMixin, categories, users, auth],

  watch: {
    selectAllProducts: function (value) {
      if (value) {
        this.productsFilterByName.forEach((product) => {
          this.checked.push(product.id);
        });
      } else {
        this.checked = [];
      }
    },
  },

  methods: {
    USERID() {
      this.product.user_id = this.authID;
    },

    addProduct() {
      let formData = new FormData();
      formData.append("name", this.product.name);
      formData.append("price", this.product.price);
      formData.append("quantity", this.product.quantity);
      formData.append("discount", this.product.discount);
      formData.append("description", this.product.description);
      formData.append("user_id", this.product.user_id);
      formData.append("category_id", this.product.category_id);

      for (let i = 0; i < this.images.length; i++) {
        formData.append("images[]", this.images[i]);
      }

      this.newData(this.newProduct, formData, "Article ajouté", "/products");
    },

    editProduct() {
      this.updateData(
        this.updateProduct,
        this.product,
        "Article mis à jour",
        "/products"
      );
    },

    loadData(url) {
      AXIOS.get(url).then((response) => {
        this.product = response.data;
        this.product.image = response.data.image;
      });
    },

    async deleteProducts() {
      await AXIOS.delete("delete-all-products/" + this.checked);
      await this.fetchProducts();

      this.checked = "";
      this.selectAllProducts = "";
    },
  },

  computed: {
    productsList() {
      return this.fetchData(this.getProducts);
    },

    productsByCategory() {
      return this.filterByAttribute(
        this.productsList,
        this.categoryList,
        "category_id",
        this.search_by_cat
      );
    },

    productsByUser() {
      return this.filterByAttribute(
        this.productsByCategory,
        this.usersList,
        "user_id",
        this.search_by_user
      );
    },

    productsFilterByName() {
      return this.fetchDataByAttribute(
        this.q,
        this.productsByUser,
        "name"
      ).slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
};
