<template>
  <div class="verif">
    <section id="hero">
      <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
        <h1>Bonjour</h1>
        <span class="text-success">{{ authName }} !</span><br />
        <hr />
        <h3>
          Nous vous avons envoyé un lien de confirmation à
          {{ authEmail }}
          <span class="info"
            ><br />Si vous l'avez pas recu, veuillez clicker sur le bouton</span
          ><br />
        </h3>
        <h5 class="text-warning" v-if="alert_resend">un nouveau mail a été envoyé</h5>
        <div class="row">
          <button type="" @click="resendEmail" class="btn btn-get-started">
            Renvoyer email
          </button>
          <router-link to="/login" class="btn btn-get-started"
            >Login</router-link
          >
        </div>
      </div>
    </section>
    <!-- End Hero Section -->
  </div>
</template>

<script>
import auth from "@/components/mixins/globals/auth/auth";
export default {
  data() {
    return {};
  },

  mixins: [auth],
  methods: {},

  mounted() {},

  computed: {},
};
</script>