<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>Oops!</h1>
          <h2>ACCESS UNAUTHORIZED</h2>
          <div class="error-details">
            Sorry, an error has occured, Requested page not authorized for you!
          </div>
          <div class="error-actions">
            <router-link
              :to="authRole ==2 ? '/' : '/dashboard'"
              class="btn btn-primary btn-lg"
            >
              <span class="glyphicon glyphicon-home"></span> Take Me Home
            </router-link>
            <a href="http://www.google.com" class="btn btn-default btn-lg"
              ><span class="glyphicon glyphicon-envelope"></span> Contact
              Support
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import template from "@/components/mixins/globals/template";
import auth from "@/components/mixins/globals/auth/auth";

export default {
  data() {
    return {};
  },

  mixins: [template, auth],
  methods: {},

  mounted() {},

  computed: {},
};
</script>
        