<template>
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box">
      <span :class="`info-box-icon bg-${bgColor} elevation-1`">
        <i :class="icon"></i>
      </span>
      <div class="info-box-content">
        <span class="info-box-text">{{ title }}</span>
        <span class="info-box-number">{{ count }}<small></small></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    count: [Number, String],
    bgColor: String,
    icon: String,
  },
};
</script>
