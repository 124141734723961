<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <!-- BEGIN  -->
            <div class="col-md-12">
              <div class="row"></div>
              <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <div class="col-md-3">
                    <h3 class="card-title">
                      Articles
                      <span class="badge badge-warning right">
                        {{ productsFilterByName.length }}
                      </span>
                    </h3>
                  </div>
                  <div class="card-tools">
                    <div
                      class="input-group input-group-sm"
                      style="width: 150px"
                    >
                      <input
                        type="text"
                        v-model="q"
                        class="form-control float-right"
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-default">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="card-tools"></div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              v-model="selectAllProducts"
                            />
                          </th>
                          <th style="width: 10px">#</th>
                          <th>Images</th>
                          <th>Nom</th>
                          <th>Prix</th>
                          <th>Quantité</th>
                          <th>Rabais</th>
                          <th>Vendeur</th>
                          <th>Ajouté le</th>
                          <th style="width: 20px">
                            <i class="fa fa-gear"></i>
                            ACTION
                          </th>

                          <!-- <th v-show="userRole != 1">...By</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(product, i) in productsFilterByName"
                          :key="i"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :value="product.id"
                              v-model="checked"
                            />
                          </td>
                          <td>{{ i + 1 }}</td>
                          <td class="d-flex">
                            <div
                              v-for="(image, index) in product.images"
                              :key="index"
                              class="image"
                            >
                              <img
                                :src="`${imgUrl + '/' + image.image_path}`"
                                class="img-circle elevation-2 user-p mr-3"
                                alt=""
                              />
                            </div>
                          </td>
                          <td>{{ product.name }}</td>
                          <td>{{ formatPrice(product.price) }}</td>
                          <td
                            :class="{
                              'text-success': product.quantity > 3,
                              'text-danger': product.quantity == 0,
                              'text-warning': product.quantity <= 3,
                            }"
                          >
                            {{ product.quantity }}
                          </td>
                          <td>{{ product.discount }}</td>
                          <template v-for="(user, i) in usersList" :key="i">
                            <th
                              v-if="product.user_id == user.id"
                              class="text-warning"
                            >
                              {{ user.name }}
                            </th>
                          </template>

                          <td>
                            {{ formatDate(product.created_at) }}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-light dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Choisir
                              </button>
                              <div
                                class="dropdown-menu"
                                x-placement="bottom-start"
                                style="
                                  position: absolute;
                                  transform: translate3d(0px, 38px, 0px);
                                  top: 0px;
                                  left: 0px;
                                  will-change: transform;
                                "
                              >
                                <button
                                  @click="deleteProduct(product.id)"
                                  type="button"
                                  class="dropdown-item"
                                >
                                  <i class="fas fa-trash"></i>
                                  Supprimer
                                </button>
                                <router-link
                                  class="dropdown-item nav-link nav-item btn"
                                  :to="{
                                    name: 'UpdateProduct',
                                    params: { id: product.id },
                                  }"
                                >
                                  <i class="fas fa-edit"></i>
                                  Modifier
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="pagination justify-content-center">
                  <el-pagination
                    background
                    :total="productsList.length"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    v-model:currentPage="currentPage"
                  />
                </div>

                <div class="card-footer clearfix row">
                  <div class="col-md'4">
                    <router-link
                      to="/new-product"
                      class="btn btn-light btn-app float-left"
                      ><i class="fas fa-plus"></i
                    ></router-link>
                  </div>
                  <div class="col-md-8">
                    <div class="">
                      <div
                        class="float-right row"
                        v-if="productsList.length > 0"
                      >
                        <div class="form-group col-md-4">
                          <label>produits par categorie</label>
                          <select class="custom-select" v-model="search_by_cat">
                            <option></option>
                            <option
                              class="input"
                              selected
                              v-for="(category, i) in categoryList"
                              :key="i"
                            >
                              {{ category.name }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Produits par utilisateur</label>
                          <select
                            class="custom-select"
                            v-model="search_by_user"
                          >
                            <option></option>
                            <option
                              class="input"
                              selected
                              v-for="(user, i) in usersList"
                              :key="i"
                            >
                              {{ user.name }}
                            </option>
                          </select>
                        </div>
                        <div class="">
                          <button
                            @click="deleteProducts"
                            v-if="checked.length > 0"
                            class="btn btn-app btn-danger btn-sm"
                          >
                            <i class="fas fa-trash"></i>
                            DELETE
                            <span class="badge badge-secondary">{{
                              checked.length
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END -->
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import products from "@/components/mixins/models/products";
import auth from "@/components/mixins/globals/auth/auth";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin, auth, products],
  methods: {},

  mounted() {},

  computed: {},
};
</script>
