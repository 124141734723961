import { useToastr } from "@/components/mixins/globals/toastr";
const toastr = useToastr();

export function successNote(message) {
  toastr.success(message);
}

export function infoNote(message) {
  toastr.info(message);
}
export function errorNote(message) {
  toastr.error(message);
}
export function warningNote(message) {
  toastr.warning(message);
}

export const setLocalItem = (item, data) => {
  return localStorage.setItem(item, data);
};
export const getLocalItem = (item) => {
  return localStorage.getItem(item);
};
export const removeLocalItem = (item) => {
  return localStorage.removeItem(item);
};
