import AXIOS from "@/components/config/AXIOS";

import { errorNote } from "./utils";

export async function fetchData(url, commit, mutation) {
  try {
    const response = await AXIOS.get(url);
    commit(mutation, response.data);
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
}

export async function createData(url, data, commit, mutation) {
  try {
    const response = await AXIOS.post(url, data);
    commit(mutation, response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
}

export async function updateData(url, id, data) {
  try {
    const response = await AXIOS.post(`${url}/${id}`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
}

export const updateDataWithImage = async (url, id, data) => {
  try {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await AXIOS.post(`${url}/${id}`, formData);
    return response.data;
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
};

export async function updateAuth(url, id, data, commit, mutation) {
  try {
    const response = await AXIOS.post(`${url}/${id}`, data);
    commit(mutation, response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
}

export async function deleteData(url, id, commit, mutation) {
  try {
    const response = await AXIOS.delete(`${url}/${id}`);
    commit(mutation, id);
    return response.data;
  } catch (error) {
    if (error.response) {
      errorNote(error.response.data.message);
    } else if (error.request) {
      errorNote(error.request);
    } else {
      errorNote(error.message);
    }
  }
}
