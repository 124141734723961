import horizontal from '@/components/views/template/HorizontalNav';
import vertical from '@/components/views/template/VerticalNav';
import Footer from '@/components/views/template/FooterNav';



export default{
    components : {
        horizontal,
        vertical,
        Footer
    }
}
