import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Chart from "chart.js";
import store from "./store";
import router from "./router";
import { createI18n } from "vue-i18n";

import en from "./i18n/en";
import fr from "./i18n/fr";

const i18n = createI18n({
  locale: "fr",
  messages: {
    en,
    fr,
  },
});

createApp(App)
  .use(router)
  .use(store)
  .use(ElementPlus)
  .use(Chart)
  .use(i18n)
  .mount("#app");
