<template>
  <main>
    <body class="hold-transition login-page">
      <div class="login-box">
        <div class="card card-outline card-primary shadow">
          <div class="card-header text-center">
            <a href="../../index2.html" class="h1"><b>Afrik</b>Raaga</a>
          </div>
          <div class="card-body">
            <form @submit.prevent="userLogin">
              <div class="input-group mb-3">
                <input
                  v-model="auth.phone"
                  type="tel"
                  class="form-control"
                  placeholder="Téléphone"
                  @input="validatePhoneNumber"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  v-model="auth.password"
                  type="password"
                  class="form-control"
                  placeholder="Mot de passe"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="">
                <button type="submit" class="btn btn-primary">
                  Me Connecter
                </button>
                <!-- <div class="">
                  <router-link
                    to="/register"
                    type="submit"
                    class="text-success"
                    style="margin-left: 4px"
                    >Nouvel utilisateur</router-link
                  >
                  /
                  <router-link
                    to="/"
                    type="submit"
                    class="text-light"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-left"> Accueil</i></router-link
                  >
                </div> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  </main>
</template>

<script>
import auth from "@/components/mixins/globals/auth/auth";
export default {
  mixins: [auth],
  data() {
    return {};
  },

  methods: {
    validatePhoneNumber() {
      this.auth.phone = this.auth.phone.replace(/[^0-9]/g, "");
    },
  },

  computed: {},
};
</script>

<style scoped>
.login-page {
  background-image: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-box {
  height: 450px;
  width: 500px;
}
</style>
