<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-black row">
              <div class="card-header">
                <h3 class="card-title">Mise à jour utilisateur</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form @submit.prevent="editUser" enctype="multipart/form-data">
                <div class="card-body row">
                  <div class="input-group mb-3 col-md-6">
                    <input
                      required
                      v-model="user.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3 col-md-6">
                    <input
                      required
                      v-model="user.email"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3 col-md-4">
                    <input
                      required
                      v-model="user.phone"
                      type="number"
                      class="form-control"
                      placeholder="Phone"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-phone"></span>
                      </div>
                    </div>
                  </div>

                  <div class="input-group mb-3 col-md-4">
                    <label class="form-control">role</label>
                    <select required v-model="user.role" class="form-control">
                      <option class="input" selected :value="1">admin</option>
                      <option class="input" :value="2">simple</option>
                      <option class="input" selected :value="3">
                        super admin
                      </option>
                    </select>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-check"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3 col-md-4">
                    <label class="form-control">status</label>
                    <select required v-model="user.status" class="form-control">
                      <option class="input" selected :value="1">actif</option>
                      <option class="input" :value="2">inactif</option>
                    </select>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-check"></span>
                      </div>
                    </div>
                  </div>

                  <div class="custom-control custom-checkbox col-md-8 mb-3">
                    <input
                      ref="checkboxState"
                      @change="passwordInput"
                      class="custom-control-input"
                      type="checkbox"
                      id="customCheckbox1"
                      value="option1"
                    />
                    <label for="customCheckbox1" class="custom-control-label"
                      >Changer le mot de passe</label
                    >
                  </div>

                  <div class="input-group mb-3 col-md-4" v-if="changePass">
                    <input
                      v-model="user.password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                 
                  <div class="input-group mb-3">
                    <img
                      :src="`${imgUrl + '/' + user.profile}`"
                      class=""
                      alt="profile image"
                    />
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-light">UPDATE</button>
                  <router-link
                    to="/users"
                    type="submit"
                    class="btn btn-danger"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-right"></i
                  ></router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>
          
          <script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin],
  methods: {
    passwordInput() {
      this.changePass = !this.changePass;
    },
    loadUser() {
      this.loadData(
        `show-user/${this.$route.params.id}`
      );
    },
  },
  created() {
    return this.loadUser();
  },
};
</script>
      
          