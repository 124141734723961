<template>
  <nav class="main-header navbar navbar-expand navbar-dark d-flex">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href=""
          class="nav-link"
        >
          {{ $t("home") }}
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a
          href=""
          class="nav-link"
        >
          contact
        </a>
      </li>
    </ul>
    <div class="navbar-nav date">
      <li class="nav-item d-none d-sm-inline-block" v-show="date">
        <strong>{{ $t("dateLabel") }}:</strong> {{ date }} |
        <strong>{{ $t("timeLabel") }} :</strong>
        {{ currentTime }}
      </li>
    </div>
  </nav>
</template>

      
      <script>
import auth from "@/components/mixins/globals/auth/auth";
import date from "@/components/mixins/globals/date";
export default {
  data() {
    return {};
  },

  mixins: [auth, date],
  methods: {},

  mounted() {},

  computed: {},
};
</script>
  
      