<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <canvas ref="chart"></canvas>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import AXIOS from "@/components/config/AXIOS";
import template from "@/components/mixins/globals/template";
export default {
  mixins: [template],
  mounted() {
    AXIOS
      .get("mensual-chart-user")
      .then((response) => {
        // const month = response.data.month;
        const user = response.data.user;
        const monthNames = [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ];

        // Créez le graphique avec Chart.js
        new Chart(this.$refs.chart.getContext("2d"), {
          type: "line",
          data: {
            labels: monthNames,
            datasets: [
              {
                label: "Utilisateurs",
                data: user,
                backgroundColor: "rgba(255, 255, 255, .2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                display: true, // Afficher l'axe Y
                beginAtZero: true,
                precision: 0, //
              },
            },
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style>
</style>