<template>
    <div>
      <horizontal />
      <vertical />
  
      <div class="component">
        <div class="content-wrapper">
          <section class="content">
            <div class="container-fluid">
              <!-- BEGIN  -->
              <div class="col-md-12">
                <div class="row"></div>
                <!-- TABLE: LATEST ORDERS -->
                <div class="card">
                  <div class="card-header border-transparent">
                    <div class="col-md-3">
                      <h3 class="card-title">
                        Produits
                        <span class="badge badge-warning right">
                          {{ order.length }}
                        </span>
                      </h3>
                    </div>
  
                    <div class="card-tools"></div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th style="width: 10px">#</th>
                            <th>Vendeur</th>
                            <th>Images</th>
                            <th>Nom</th>
                            <th>Prix</th>
                            <th>Quantité</th>
                            <th>Total</th>
                            <th>Date d'ajout</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(o, i) in order" :key="i">
                            <td>{{ i + 1 }}</td>
  
                            <template v-for="(user, i) in usersList" :key="i">
                              <th
                                v-if="o.user_id == user.id"
                                class="text-warning"
                              >
                                <div class="row">
                                    <h5 class="mr-3">{{ user.name}} </h5>
                                    <h5 class="text-white">{{ user.phone}} </h5>
                                </div>
                              </th>
                            </template>
                            <td class="d-flex">
                            <div
                              v-for="(image, index) in o.images"
                              :key="index"
                              class="image"
                            >
                              <img
                                :src="`${imgUrl + '/' + image.image_path}`"
                                class="img-circle elevation-2 user-p mr-3"
                                alt=""
                              />
                            </div>
                          </td>
                            <td>{{ o.name }}</td>
  
                            <td>{{ formatPrice(o.price) }}</td>
                            <td>{{ o.quantity }}</td>
                            <td>{{ formatPrice(o.quantity * o.price) }}</td>
                            <td>
                              {{ formatDate(o.created_at) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END -->
            </div>
          </section>
        </div>
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
  import template from "@/components/mixins/globals/template";
  import orders from "@/components/mixins/models/orders";
  
  export default {
    data() {
      return {};
    },
  
    mixins: [template, orders],
    methods: {
      async loadPub() {
        await this.loadData(`show-order-products/${this.$route.params.id}`);
      },
    },
  
    mounted() {},
  
    computed: {},
  
    created() {
      return this.loadPub();
    },
  };
  </script>
  
  <style scoped>
  .old-image {
    width: 200px;
    height: 200px;
  }
  
  .new-image {
    width: 200px;
    height: 200px;
  }
  </style>
  