import exportMixin from '@/components/mixins/globals/exports/export';
import AXIOS from "@/components/config/AXIOS";
export default {
    mixins: [exportMixin],

    watch: {
        selectAllPubs: function (value) {
            if (value) {
                this.pubsFilterByTitle.forEach(pub => {
                    this.checked.push(pub.id);
                });
            } else {
                this.checked = [];
            }
        }
    },

    methods: {
        addPub() {
            let formData = new FormData();
            formData.append('title', this.pub.title);
            formData.append('image', this.image);

            this.newData(this.newPub, formData, 'Publicité ajoutée avec succes');
        },

        editPub() {
            this.pub.image = this.image;
            this.updateData(this.updatePub, this.pub, 'Publicité mise à jour');
        },
        
        async loadData(url) {
            await AXIOS.get(url).then((response) => {
                this.pub = response.data;
                this.pub.image = response.data.image;
            });
        },

        async deletePubs() {
            await AXIOS.delete('delete-all-pubs/' + this.checked);
            await this.fetchPubs();

            this.checked = "";
            this.selectAllPubs = "";
        },
    },

    computed: {

    pubList() {
            return this.fetchData(this.getPubs);
        },

        pubsFilterByTitle() {
            return this.fetchDataByAttribute(this.q, this.pubList, "title")
            .slice(
                this.pageSize * this.currentPage - this.pageSize,
                this.pageSize * this.currentPage)
        },
    },
}