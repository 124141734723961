import { mapActions, mapMutations } from "vuex";
export default {
    methods: {
        ...mapMutations(['SET_LAST_ACTIVITY']),
        ...mapActions([
            'register',
            "login",
            "checkInactivity",
            'logout',

            'fetchUsers',
            'newUser',
            'updateUser',
            'deleteUser',

            'fetchCategories',
            'newCategorie',
            'updateCategorie',
            'deleteCategorie',

            'fetchPubs',
            'newPub',
            'updatePub',
            'deletePub',

            'fetchOrders',
            'deleteOrder',

            "fetchProducts",
            "newProduct",
            "updateProduct",
            "deleteProduct",
        ]),

    }
}