import mapactions from "@/components/mixins/globals/exports/mapactions";
export default {
  mixins: [mapactions],
  methods: {
    async load() {
      if (this.isLoggedIn) {
        return [
          await this.fetchUsers(),
          await this.fetchCategories(),
          await this.fetchPubs(),
          await this.fetchOrders(),
          await this.fetchProducts(),
        ];
      } else {
        // 
      }
    },
  },
};
