import datas from '@/components/mixins/globals/exports/datas';
import mapactions from '@/components/mixins/globals/exports/mapactions';
import mapgetters from '@/components/mixins/globals/exports/mapgetters';
import mounted from '@/components/mixins/globals/exports/mounted';
import methods from '@/components/mixins/globals/exports/methods';



export default{
    mixins: [datas,mapactions,mapgetters,mounted,methods],
}