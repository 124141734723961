<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-black row">
              <div class="card-header">
                <h3 class="card-title">MISE A JOUR PRODUIT</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form @submit.prevent="editProduct" enctype="multipart/form-data">
                <div class="card-body">
                  <div class="row">
                    <div class="input-group col-md-6">
                      <input
                        required
                        v-model="product.name"
                        type="text"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        required
                        v-model="product.price"
                        type="number"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        required
                        v-model="product.quantity"
                        type="number"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div class="input-group mb-3 col-md-2">
                      <input
                        required
                        v-model="product.discount"
                        type="number"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div class="input-group mb-3 col-md-6">
                      <!-- <label class="form-control">Vendeur</label> -->
                      <select
                        required
                        v-model="product.user_id"
                        class="form-control"
                      >
                        <option
                          class="input"
                          v-for="(user, i) in usersList"
                          :key="i"
                          :value="user.id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>

                    <div class="input-group mb-3 col-md-6">
                      <input
                        class="form-control"
                        name="images"
                        type="file"
                        id=""
                        ref="imageInput"
                        @change="saveImages"
                        multiple
                      />
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Description</label>
                        <textarea
                          v-model="product.description"
                          class="form-control"
                          rows="3"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="image-container d-flex justify-content-between" v-if="selected_images.length == 0">
                    <div
                      class="image-wrapper"
                      v-for="(image, index) in product.images"
                      :key="index"
                    >
                      <div class="input-group mb-3 d-flex" v-if="image">
                        <img
                          :src="`${imgUrl + '/' + image.image_path}`"
                          class="old-image"
                          alt="Ancienne Image"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="image-container d-flex justify-content-between">
                    <div
                      v-for="(image, index) in selected_images"
                      :key="index"
                      class="image-wrapper"
                    >
                      <div class="input-group mb-3 d-flex">
                        <img
                          :src="image"
                          class="new-image mr-3"
                          alt="Image sélectionnée"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-light">VALIDER</button>
                  <router-link
                    to="/products"
                    type="submit"
                    class="btn btn-danger"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-right"></i
                  ></router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import categories from "@/components/mixins/models/categories";
import products from "@/components/mixins/models/products";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin, categories, products],
  methods: {
    loadProduct() {
      this.loadData(`show-product/${this.$route.params.id}`);
    },
  },

  mounted() {},

  computed: {},

  created() {
    return this.loadProduct();
  },
};
</script>
<style scoped>
.old-image {
  width: 200px;
  height: 200px;
}

.new-image {
  width: 200px;
  height: 200px;
}
</style>
