import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "userAuth",

            'getUsers',
            'getCategories',
            'getPubs',
            'getOrders',
            'getProducts',
        ]),
    }
}