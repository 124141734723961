<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <div class="card card-black row">
              <div class="card-header">
                <h3 class="card-title">NEW USER</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form @submit.prevent="" enctype="multipart/form-data">
               
              
                <div class="card-footer">
                  <button type="submit" class="btn btn-light">ADD</button>
                  <router-link
                    to="/users"
                    type="submit"
                    class="btn btn-danger"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-right"></i
                  ></router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>
        
        <script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import auth from "@/components/mixins/globals/auth/auth";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin, auth],

  mounted() {},

  computed: {},
};
</script>
    
        