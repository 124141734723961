import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

// import StarterPage from '@/components/views/template/landing/StarterPage'


import LoginPage from '@/components/views/models/auth/LoginPage'
import RegisterPage from '@/components/views/models/auth/RegisterPage'
import VerificationEmail from '@/components/views/models/auth/VerificationEmail'
import UnAutorized from '@/components/views/models/mods/UnAutorized'


import LandingPage from '@/components/views/models/mods/LandingPage'
import DashBoard from '@/components/views/models/mods/DashBoard'

import UsersIndex from '@/components/views/models/mods/users/UsersIndex'
import NewUser from '@/components/views/models/mods/users/NewUser'
import UpdateUser from '@/components/views/models/mods/users/UpdateUser'
import UpdateAuth from '@/components/views/models/mods/users/UpdateAuth'
import ChartUser from '@/components/views/models/mods/users/ChartUser'

import CategoriesIndex from '@/components/views/models/mods/categories/CategoriesIndex'
import NewCategory from '@/components/views/models/mods/categories/NewCategory'
import UpdateCategory from '@/components/views/models/mods/categories/UpdateCategory'

import PubsIndex from '@/components/views/models/mods/pubs/PubsIndex'
import NewPub from '@/components/views/models/mods/pubs/NewPub'
import UpdatePub from '@/components/views/models/mods/pubs/UpdatePub'


import OrdersIndex from '@/components/views/models/mods/orders/OrdersIndex'
import ShowOrder from '@/components/views/models/mods/orders/ShowOrder'

import ProductsIndex from '@/components/views/models/mods/products/ProductsIndex'
import UpdateProduct from '@/components/views/models/mods/products/UpdateProduct'
import NewProduct from '@/components/views/models/mods/products/NewProduct'

const routes = [
    // Auth
    {
        name: 'LoginPage',
        path: '/',
        component: LoginPage,
        meta: {
            title: 'AfrikRaaga Back-office'
        }
    },

    {
        name: 'RegisterPage',
        path: '/register',
        component: RegisterPage,
        meta: {
            title: 'App Sign Up'
        }
    },

    {
        name: 'VerificationEmail',
        path: '/verification-email',
        component: VerificationEmail,
        meta: {
            title: 'vérification de mail'
        }
    },

    {
        name: 'UnAutorized',
        path: '/unautorized',
        component: UnAutorized,
        meta: {
            title: 'acces non autorisé'
        }
    },

    /* */
    {
        name: 'LandingPage',
        path: '/landingPage',
        component: LandingPage,
        meta: {
            title: 'landing page',
            requiresAuth: true
        }
    },

    {
        name: 'UsersIndex',
        path: '/users',
        component: UsersIndex,
        meta: {
            title: 'users',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'CategoriesIndex',
        path: '/categories',
        component: CategoriesIndex,
        meta: {
            title: 'categories',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'PubsIndex',
        path: '/pubs',
        component: PubsIndex,
        meta: {
            title: 'publicités',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'OrdersIndex',
        path: '/orders',
        component: OrdersIndex,
        meta: {
            title: 'commandes',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'ProductsIndex',
        path: '/products',
        component: ProductsIndex,
        meta: {
            title: 'articles',
            requiresAuth: true,
            role : [1,3],
        }
    },

    {
        name: 'ChartUser',
        path: '/chart-user',
        component: ChartUser,
        meta: {
            title: 'users chart',
            requiresAuth: true,
            role : [1,3],
        }
    },

    {
        name: 'NewUser',
        path: '/new-user',
        component: NewUser,
        meta: {
            title: 'new user',
            requiresAuth: true,
            role : [1,3]
        }
    },
    {
        name: 'NewCategory',
        path: '/new-category',
        component: NewCategory,
        meta: {
            title: 'new category',
            requiresAuth: true,
            role : [1,3]
        }
    },
    {
        name: 'NewPub',
        path: '/new-pub',
        component: NewPub,
        meta: {
            title: 'new pub',
            requiresAuth: true,
            role : [1,3]
        }
    },
    {
        name: 'NewProduct',
        path: '/new-product',
        component: NewProduct,
        meta: {
            title: 'new product',
            requiresAuth: true,
            role : [1,3]
        }
    },


    {
        name: 'UpdateUser',
        path: '/update-user/update/:id?',
        component: UpdateUser,
        meta: {
            title: 'update user',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'UpdateCategory',
        path: '/update-category/update/:id?',
        component: UpdateCategory,
        meta: {
            title: 'update category',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'UpdatePub',
        path: '/update-pub/update/:id?',
        component: UpdatePub,
        meta: {
            title: 'modifier la publicité',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'UpdateProduct',
        path: '/update-product/update/:id?',
        component: UpdateProduct,
        meta: {
            title: 'mise à jour du produit',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'ShowOrder',
        path: '/show-order/products/:id?',
        component: ShowOrder,
        meta: {
            title: 'produits de la commande',
            requiresAuth: true,
            role : [1,3],
        }
    },
    {
        name: 'UpdateAuth',
        path: '/update-auth/update/:id?',
        component: UpdateAuth,
        meta: {
            title: 'update auth',
            requiresAuth: true,
        }
    },

    {
        name: 'DashBoard',
        path: '/dashboard/:name?',
        component: DashBoard,
        meta: {
            title: 'dashboard',
            requiresAuth: true,
            role : [1,3],
        }
    },
];

const router = createRouter(
    {
        history: createWebHistory(),
        routes,
    }
)


// routes protection (middleware)

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            const requiredRole = to.meta.role;
            const userRole = store.getters.userAuth.role;
            if (requiredRole && !requiredRole.includes(userRole)) {
                next("/unautorized");
            }else{
                next();
                return;
            }     
        } else {
            next("/");
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    document.title = to.meta.title;
});

export default router;