<template>
    <div>
      <horizontal />
      <vertical />
  
      <div class="component">
        <div class="content-wrapper">
          <section class="content">
            <div class="container-fluid">
              <!-- BEGIN  -->
              <div class="col-md-12">
                <div class="row"></div>
                <!-- TABLE: LATEST ORDERS -->
                <div class="card">
                  <div class="card-header border-transparent">
                    <div class="col-md-3">
                      <h3 class="card-title">
                        Publicités
                        <span class="badge badge-warning right">
                          {{ pubsFilterByTitle.length }}
                        </span>
                      </h3>
                    </div>
                    <div class="card-tools">
                      <div
                        class="input-group input-group-sm"
                        style="width: 150px"
                      >
                        <input
                          type="text"
                          v-model="q"
                          class="form-control float-right"
                          placeholder="Search"
                        />
                        <div class="input-group-append">
                          <button type="submit" class="btn btn-default">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
  
                    <div class="card-tools"></div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" v-model="selectAllPubs" />
                            </th>
                            <th style="width: 10px">#</th>
                            <th>Image</th>
                            <th>Titre</th>
                            <th>Date d'ajout</th>
                            <th style="width: 20px">
                              <i class="fa fa-gear"></i>
                              ACTION
                            </th>
  
                            <!-- <th v-show="userRole != 1">...By</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(pub, i) in pubsFilterByTitle" :key="i">
                            <td>
                              <input
                                type="checkbox"
                                :value="pub.id"
                                v-model="checked"
                              />
                            </td>
                            <td>{{ i + 1 }}</td>
                            <th>
                              <div class="image user-p">
                                <img
                                  :src="`${imgUrl + '/' + pub.image}`"
                                  class="img-circle elevation-2 user-p"
                                  alt=""
                                />
                              </div>
                            </th>
                            <td>{{ pub.title }}</td>
                            <td>
                              {{ formatDate(pub.created_at) }}
                            </td>
                            <td>
                              <div class="btn-group">
                                <button
                                  type="button"
                                  class="btn btn-light dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Choisir
                                </button>
                                <div
                                  class="dropdown-menu"
                                  x-placement="bottom-start"
                                  style="
                                    position: absolute,
                                    transform: translate3d(0px, 38px, 0px),
                                    top: 0px,
                                    left: 0px,
                                    will-change: transform,
                                  "
                                >
                                  <button
                                    @click="deletePub(pub.id)"
                                    type="button"
                                    class="dropdown-item"
                                  >
                                    <i class="fas fa-trash"></i>
                                    Supprimer
                                  </button>
                                  <router-link
                                    class="dropdown-item nav-link nav-item btn"
                                    :to="{
                                      name: 'UpdatePub',
                                      params: { id: pub.id },
                                    }"
                                  >
                                    <i class="fas fa-edit"></i>
                                    Modifier
                                  </router-link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
  
                  <div class="pagination justify-content-center">
                    <el-pagination
                      background
                      :total="pubList.length"
                      :page-size="pageSize"
                      layout="prev, pager, next"
                      v-model:currentPage="currentPage"
                    />
                  </div>
  
                  <div class="card-footer clearfix row">
                    <div class="col-md'4">
                      <router-link
                        to="/new-pub"
                        class="btn btn-light btn-app float-left"
                        ><i class="fas fa-plus"></i
                      ></router-link>
                    </div>
                    <div class="col-md-8">
                      <div class="">
                        <div
                          class="float-right row"
                          v-show="pubList.length > 0"
                        >
                        
                          <div class="">
                            <button
                              @click="deletePubs"
                              v-if="checked.length > 0"
                              class="btn btn-app btn-danger btn-sm"
                            >
                              <i class="fas fa-trash"></i>
                              Supprimer
                              <span class="badge badge-secondary">{{
                                checked.length
                              }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END -->
            </div>
          </section>
        </div>
      </div>
  
      <Footer />
    </div>
  </template>
          
          <script>
  import template from "@/components/mixins/globals/template";
  import usersMixin from "@/components/mixins/models/users";
  import pubs from "@/components/mixins/models/pubs";
  import auth from "@/components/mixins/globals/auth/auth";
  
  export default {
    data() {
      return {};
    },
  
    mixins: [template, usersMixin, auth, pubs],
    methods: {},
  
    mounted() {},
  
    computed: {},
  };
  </script>
      
          