<template>
  <main>
    <body class="register-page" style="min-height: 542px">
      <div class="register-box">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <a href="../../index2.html" class="h1"><b>Sign</b>Up</a>
            <div v-if="isLoading" class="loader text-white badge badge-warning">
              Chargement en cours...
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="registerUser">
              <div class="input-group mb-3">
                <input
                  v-model="user.name"
                  type="text"
                  class="form-control"
                  placeholder="Full name"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  v-model="user.email"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-group mb-3 col-md-4">
                  <select
                    @click="getIndicatif"
                    required
                    v-model="indicatif"
                    class="form-control"
                  >
                    <option disabled selected value="">Country</option>
                    <option
                      v-for="(countrie, i) in countries"
                      :key="i"
                      :value="countrie.indicatif"
                      class="input"
                    >
                      {{ countrie.name }}
                    </option>
                  </select>
                </div>
                <div class="input-group mb-3 col-md-8">
                  <input
                    v-model="user.phone"
                    type="text"
                    name="phone"
                    pattern="[0-9]+"
                    maxlength="11"
                    class="form-control"
                    placeholder="Phone"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-phone"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  v-model="user.confirmed_password"
                  type="password"
                  class="form-control"
                  placeholder="Retype password"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  @change="profileInput"
                  class="custom-control-input"
                  type="checkbox"
                  id="customCheckbox1"
                  value="option1"
                />
                <label for="customCheckbox1" class="custom-control-label"
                  >Ajouter image de profile</label
                >
              </div>

              <div class="input-group mb-3" v-if="changeProfile">
                <label class="form-control" for="customFile">profile</label>
                <input
                  class="form-control"
                  name="image"
                  type="file"
                  id=""
                  ref="imageInput"
                  @change="saveImage"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-file"></span>
                  </div>
                </div>
              </div>

              <div></div>

              <div class="">
                <button type="submit" class="btn btn-warning">Sign Up</button>
                <div class="">
                  <router-link
                    to="/login"
                    type="submit"
                    class="text-success"
                    style="margin-left: 4px"
                    >Sign In</router-link
                  >
                  /
                  <router-link
                    to="/"
                    type="submit"
                    class="text-light"
                    style="margin-left: 4px"
                    ><i class="fas fa-arrow-left"> Accueil</i></router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  </main>
</template>

<script>
import auth from "@/components/mixins/globals/auth/auth";
export default {
  mixins: [auth],
};
</script>

<style>
</style>