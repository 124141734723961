<template>
    <div>
      <horizontal />
      <vertical />
  
      <div class="component">
        <div class="content-wrapper">
          <section class="content">
            <div class="container-fluid">
              <div class="card card-black row">
                <div class="card-header">
                  <h3 class="card-title">Ajout publicité</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form @submit.prevent="addPub" enctype="multipart/form-data">
                  <div class="card-body">
                    <div class="row">
                      <div class="input-group mb-3 col-md-6">
                        <input
                          required
                          v-model="pub.title"
                          type="text"
                          class="form-control"
                          placeholder="Nom"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-user"></span>
                          </div>
                        </div>
                      </div>
                      <div class="input-group mb-3 col-md-6">
                        <label class="form-control" for="customFile">image</label>
                        <input
                          required
                          class="form-control"
                          name="image"
                          type="file"
                          id=""
                          ref="imageInput"
                          @change="saveImage"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-file"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="image">
                        <img :src="selected_image" alt="Image sélectionnée" />
                      </div>
                    </div>
                  </div>
  
                  
                  <!-- /.card-body -->
  
                  <div class="card-footer">
                    <button type="submit" class="btn btn-light">Ajouter</button>
                    <router-link
                      to="/pubs"
                      type="submit"
                      class="btn btn-danger"
                      style="margin-left: 4px"
                    >
                      <i class="fas fa-arrow-right"></i>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
  import template from "@/components/mixins/globals/template";
  import pubs from "@/components/mixins/models/pubs";
  
  export default {
    data() {
      return {};
    },
  
    mixins: [template, pubs],
    methods: {},
  
    mounted() {},
  
    computed: {},
  };
  </script>
  