<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <!-- BEGIN  -->
            <div class="col-md-12">
              <div class="row"></div>
              <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <div class="col-md-3">
                    <h3 class="card-title">
                      Commandes
                      <span class="badge badge-warning right">
                        {{ ordersFilterByUser.length }}
                      </span>
                    </h3>
                  </div>

                  <div class="card-tools"></div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" v-model="selectAllOrders" />
                          </th>
                          <th style="width: 10px">#</th>
                          <th>Utilisateur</th>
                          <th>Total Commande</th>
                          <th>Code Commande</th>
                          <th>Statut</th>
                          <th>Date</th>
                          <th style="width: 20px">
                            <i class="fa fa-gear"></i>
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(order, i) in ordersFilterByUser"
                          :key="i"
                          @click="showOrderDetails(order)"
                        >
                          <td>
                            <input
                              type="checkbox"
                              :value="order.id"
                              v-model="checked"
                            />
                          </td>
                          <td>{{ i + 1 }}</td>

                          <template v-for="(user, i) in usersList" :key="i">
                            <th
                              v-if="order.user_id == user.id"
                              class="text-warning"
                            >
                              {{ user.name }}
                            </th>
                          </template>

                          <td>{{ formatPrice(order.total_amount) }}</td>
                          <td>{{ order.code }}</td>
                          <td>
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'statusSwitch' + order.id"
                                :checked="order.status === 2"
                                @change="toggleOrderStatus(order)"
                              />
                              <label
                                class="custom-control-label"
                                :for="'statusSwitch' + order.id"
                              >
                                {{ order.status === 2 ? "validée" : "encours" }}
                              </label>
                            </div>
                          </td>
                          <td>
                            {{ formatDate(order.created_at) }}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-light dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Choose
                              </button>
                              <div
                                class="dropdown-menu"
                                x-placement="bottom-start"
                                style="
                                  position: absolute;
                                  transform: translate3d(0px, 38px, 0px);
                                  top: 0px;
                                  left: 0px;
                                  will-change: transform;
                                "
                              >
                                <button
                                  @click="deleteOrder(order.id)"
                                  type="button"
                                  class="dropdown-item"
                                >
                                  <i class="fas fa-trash"></i>
                                  Delete
                                </button>
                                <router-link
                                  class="dropdown-item nav-link nav-item btn"
                                  :to="{
                                    name: 'ShowOrder',
                                    params: { id: order.id },
                                  }"
                                >
                                  <i class="fa fa-eye"></i>
                                  Details
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="pagination justify-content-center">
                  <el-pagination
                    background
                    :total="orderList.length"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    v-model:currentPage="currentPage"
                  />
                </div>

                <div class="card-footer clearfix row">
                  <div class="col-md-12">
                    <div class="">
                      <div class="row" v-if="orderList.length > 0">
                        <div class="form-group col-md-2">
                          <label>Commandes par utilisateur</label>
                          <select class="custom-select" v-model="user_query">
                            <option></option>
                            <option
                              class="input"
                              selected
                              v-for="(user, i) in usersList"
                              :key="i"
                            >
                              {{ user.name }}
                            </option>
                          </select>
                        </div>
                        <div class="">
                          <button
                            @click="deleteOrders"
                            v-if="checked.length > 0"
                            class="btn btn-app btn-danger btn-sm"
                          >
                            <i class="fas fa-trash"></i>
                            DELETE
                            <span class="badge badge-secondary">{{
                              checked.length
                            }}</span>
                          </button>
                        </div>
                      </div>
                      <tfoot class="ml-3">
                        <h5>
                          Total des commandes :
                          <span class="text-green">{{
                            formatPrice(sumTotal)
                          }}</span>
                          FCFA
                        </h5>
                        <div v-if="selectedOrder" class="order-details">
                          <h5>
                            Détails de livraison pour la commande
                            <span class="text-red">{{
                              selectedOrder.code
                            }}</span>
                            :
                          </h5>
                          <p><strong>Nom :</strong> {{ selectedOrder.name }}</p>
                          <p>
                            <strong>Téléphone :</strong>
                            {{ selectedOrder.phone }}
                          </p>
                          <hr />
                          <p>
                            <strong>Adresse de livraison :</strong>
                            {{ selectedOrder.address }}
                          </p>
                        </div>

                        <div>
                          <div
                            class="float-right form-group col-md-12"
                            v-if="percent !== null"
                          >
                            <label>Modifier la commission</label>
                            <input
                              @change="updatePercent"
                              required
                              v-model="percent"
                              type="number"
                              class="form-control"
                              placeholder="Commission"
                            />
                          </div>
                          <div
                           class="float-right form-group col-md-12"
                            v-if="percent === null"
                          >
                            <label>Définir une nouvelle commission</label>
                            <input
                              @change="addPercent"
                              required
                              v-model="commission.percent"
                              type="number"
                              class="form-control"
                              placeholder="Nouvelle commission"
                            />
                          </div>
                        </div>
                      </tfoot>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END -->
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import orders from "@/components/mixins/models/orders";
import AXIOS from "@/components/config/AXIOS";

export default {
  data() {
    return {
      percent: null,
      selectedOrder: null,
    };
  },

  mixins: [template, usersMixin, orders],
  methods: {
    showOrderDetails(order) {
      this.selectedOrder = order;
    },

    async fetchPercent() {
      await AXIOS
        .get("percent")
        .then((response) => {
          this.percent = response.data;
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du nombre : ", error);
        });
    },
    async addPercent() {
      if (this.commission.percent !== "") {
        try {
          await AXIOS.post("add-percent", { percent: this.commission.percent });
          this.percent = this.commission.percent;
          this.successNote('Commission définie avec succes !')
        } catch (error) {
          console.error("Erreur lors de l'ajout de la commission : ", error);
        }
      } else {
        console.error("Veuillez entrer une valeur pour la commission.");
      }
    },

    // async addPercent() {
    //   if (this.commission.percent !== null) {
    //     await AXIOS
    //       .post("add-percent", { percent: this.commission.percent })
    //       .then((response) => {
    //         console.log("Commission définie");
    //       })
    //       .catch((error) => {
    //         console.error("Erreur de l'ajout de la commission : ", error);
    //       });
    //   } else {
    //     console.error("Une commission est déja définie !");
    //   }
    // },

    async updatePercent() {
      try {
        await AXIOS.put("update-percent", { percent: this.percent });
        this.successNote('Commission mise à jour avec succes !');
      } catch (error) {
        console.error("Erreur lors de la mise à jour du pourcentage : ", error);
      }
    },
  },

  mounted() {},

  computed: {},

  created() {
    this.fetchPercent();
  },
};
</script>
