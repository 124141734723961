import {
  fetchData,
  createData,
  updateData,
  deleteData,
} from "@/components/utils/actions";
import {
  FETCH_DATA,
  NEW_DATA,
  UPDATE_DATA,
  DELETE_DATA,
} from "@/components/utils/mutations";

const state = {
  products: [],
};

const getters = {
  getProducts: (state) => state.products,
};

const actions = {
  async fetchProducts({ commit }) {
    const response = await fetchData("products", commit, "FETCH_PRODUCTS");
    return response;
  },

  async newProduct({ commit }, product) {
    const response = await createData(
      "new-product",
      product,
      commit,
      "NEW_PRODUCT"
    );
    return response;
  },

  async updateProduct({ commit }, product) {
    const response = await updateData("update-product", product.id, product);
    return response;
  },

  async deleteProduct({ commit }, id) {
    const response = await deleteData(
      "delete-product",
      id,
      commit,
      "DELETE_PRODUCT"
    );
    return response;
  },
};

const mutations = {
  FETCH_PRODUCTS(state, product) {
    FETCH_DATA(state, product, "products");
  },

  NEW_PRODUCT: (state, product) => {
    NEW_DATA(state, product, "products");
  },
  UPDATE_PRODUCT: (state, updated) => {
    UPDATE_DATA(state, "product", "products", updated);
  },

  DELETE_PRODUCT: (state, id) => {
    DELETE_DATA(state, id, "products");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
