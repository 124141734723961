import {
  fetchData,
  createData,
  updateData,
  deleteData,
} from "@/components/utils/actions";
import {
  FETCH_DATA,
  NEW_DATA,
  UPDATE_DATA,
  DELETE_DATA,
} from "@/components/utils/mutations";

const state = {
  users: [],
};

const getters = {
  getUsers: (state) => state.users,
};

const actions = {
  async fetchUsers({ commit }) {
    await fetchData("users", commit, "FETCH_USERS");
  },

  async newUser({ commit }, user) {
    await createData("new-user", user, commit, "NEW_USER");
  },

  async updateUser({ commit }, user) {
    await updateData("update-user", user.id, user);
  },

  async deleteUser({ commit }, id) {
    await deleteData("delete-user", id, commit, "DELETE_USER");
  },
};

const mutations = {
  FETCH_USERS(state, user) {
    FETCH_DATA(state, user, "users");
  },

  NEW_USER: (state, user) => {
    NEW_DATA(state, user, "users");
  },
  UPDATE_USER: (state, updated) => {
    UPDATE_DATA(state, "user", "users", updated);
  },

  DELETE_USER: (state, id) => {
    DELETE_DATA(state, id, "users");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
