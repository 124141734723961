import { useToastr } from "@/components/mixins/globals/toastr";
const toastr = useToastr();

export default {
  methods: {
    async loginInterface(phone, password) {
      const User = new FormData();
      User.append("phone", phone);
      User.append("password", password);
      if (phone && password) {
        await this.login(User);
        if (this.userAuth) {
          if (this.authStatus == 1) {
            if (this.authRole == 1 || this.authRole == 3) {
              this.$router.push(`/dashboard/${this.userAuth.name}`);
            } else this.$router.push("/");
          } else {
            this.warningNote("Compte inactif");
          }
        } else {
          this.errorNote("Utilisateur introuvable");
        }
      } else {
        this.errorNote("Entrez vos identifiants");
      }
    },

    async registerValidation() {
      if (
        this.user.name &&
        this.user.email &&
        this.user.phone &&
        this.user.password &&
        this.user.confirmed_password
      ) {
        if (this.user.password.length >= 8) {
          if (this.user.password === this.user.confirmed_password) {
            const existingUser = await this.checkExistingUser(
              this.user.email,
              this.user.phone
            );
            if (!existingUser) {
              this.isLoading = true;

              let formData = new FormData();
              formData.append("name", this.user.name);
              formData.append("role", this.user.role);
              formData.append("phone", this.user.phone);
              formData.append("email", this.user.email);
              formData.append("profile", this.image);
              formData.append("password", this.user.password);

              this.newData(this.register, formData, "Inscription réussie");
              await this.delay(5000);
              await this.loginInterface(this.user.phone, this.user.password);
            } else {
              toastr.error("Cet utilisateur existe déja");
            }
          } else {
            toastr.info("Les mots de passe ne correspondent pas");
          }
        } else {
          toastr.error("Le mot de passe doit comporter au moins 8 caractères");
        }
      } else {
        toastr.info("Veuillez remplir tous les champs");
      }
    },

    async delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    /*_______________________________________________________________ */

    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    authId(model) {
      model.auth_id = this.authID;
    },

    capitalizeFirstLetter(model, attribute) {
      if (model[attribute].length > 0) {
        model[attribute] =
          model[attribute].charAt(0).toUpperCase() +
          model[attribute].slice(1).toLowerCase();
      }
    },

    lowerCaseInput(model, attribute) {
      model[attribute] = model[attribute].toLowerCase();
    },

    upperCaseInput(model, attribute) {
      model[attribute] = model[attribute].toUpperCase();
    },

    saveImage(e) {
      const fileInput = this.$refs.imageInput;
      const file = fileInput.files[0];
      const allowedExtensions = /\.(jpg|jpeg|png)$/i;

      if (!allowedExtensions.test(file.name)) {
        this.errorNote(".jpg, .jpeg ou .png");
        fileInput.value = "";
      } else {
        this.selected_image = URL.createObjectURL(file);
        this.image = e.target.files[0];
      }
    },

    saveImages(e) {
      const fileInput = this.$refs.imageInput;
      const files = fileInput.files;
      const allowedExtensions = /\.(jpg|jpeg|png)$/i;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!allowedExtensions.test(file.name)) {
          this.errorNote(
            "Les fichiers doivent être au format .jpg, .jpeg ou .png"
          );
          fileInput.value = "";
          return;
        }
      }

      const imageUrls = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const imageUrl = URL.createObjectURL(file);
        imageUrls.push(imageUrl);
      }

      this.selected_images = imageUrls;
      this.images = e.target.files;
    },

    saveFile(e) {
      const fileInput = this.$refs.fileIpt;
      const file = fileInput.files[0];
      const allowedExtensions = /\.(pdf)$/i;

      if (!allowedExtensions.test(file.name)) {
        this.errorNote(".pdf");
        fileInput.value = "";
      } else {
        this.file = e.target.files[0];
      }
    },

    fetchData(method) {
      return method;
    },
    /*simple data fetch  return method.sort((a, b) => a.name.localeCompare(b.name));*/

    fetchLast(action, limit) {
      return action.slice(action.length - limit);
    },

    /*fetchLast(products,3) */

    report(upMethod, downMethod) {
      return ((upMethod.length / downMethod.length) * 100).toFixed(2);
    },

    async newData(method, model, message, route) {
      try {
        const response = await method(model);
        if (response?.status === true) {
          this.$router.push(route);
          toastr.success(`${message}`);
        }
      } catch (error) {
        if (error.response) {
          this.errorNote(error.response.data.message);
        } else if (error.request) {
          this.errorNote(error.request);
        } else {
          this.errorNote(error.message);
        }
      }
    },

    async updateData(method, model, message, route) {
      try {
        const response = await method(model);
        console.log(response);
        
        if (response?.status === true) {
          this.$router.push(route);
          toastr.success(`${message}`);
        }
      } catch (error) {
        if (error.response) {
          this.errorNote(error.response.data.message);
        } else if (error.request) {
          this.errorNote(error.request);
        } else {
          this.errorNote(error.message);
        }
      }
    },
    /*
    this is to update a data.The same like newData
    */

    fetchDataByAttributeCompare(method, attribute, operator, value) {
      return method.filter((modele) => {
        switch (operator) {
          case ">":
            return modele[attribute] > value;
          case "<":
            return modele[attribute] < value;
          case "=":
            return modele[attribute] == value;
          default:
            return false;
        }
      });
    },
    /* ex : fetchDataByAttributeComapre(fetchData,quantity,'>',0) */

    fetchDataByUser(method) {
      return method.filter((model) => model.user_id == this.userId);
    },
    /*ex : fetch...(products) */

    fetchDataByQueryOnName(q, method) {
      if (q) {
        return method.filter((model) => {
          return q
            .toLowerCase()
            .split(" ")
            .every((v) => model.name.toLowerCase().includes(v));
        });
      } else {
        return method;
      }
    },
    /*ex : fetchD...(this.q, fetchProducts) */

    fetchDataByAttribute(q, method, attr) {
      if (q) {
        return method.filter((model) => {
          return q
            .toLowerCase()
            .split(" ")
            .every((v) => model[attr].toLowerCase().includes(v));
        });
      } else {
        return method;
      }
    },
    /*ex : fetchD...(this.q, fetchProducts, "slug") */

    fetchDataByQueryOnAttribute(q, method, attribute) {
      if (q) {
        return method.filter((model) => model[attribute] === q);
      } else {
        return method;
      }
    },
    /*ex : fetchD...(this.q, fetchProducts) */

    filterByAttribute(method, externFetchDataMethod, foreignKey, query) {
      return method.filter((model) => {
        let gotModel = externFetchDataMethod.find(
          (gotModel) => gotModel.id == model[foreignKey]
        );
        return gotModel?.name.match(query);
      });
    },
    /*ex : filterBy...(sellsByUser, allProducts, 'product_id') */

    sumOnAttribute(method, attribute) {
      return method.reduce((sum, cur) => (sum += parseInt(cur[attribute])), 0);
    },
    /*ex : sumOnAtt(allProducts, quantity) */

    formatDate(value) {
      return new Date(value).toLocaleString();
    },
    /*
    this function allows formatting dates
     */

    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      // can be format with "." also.In this case I just use sapce
    },

    statusChange(value, userName) {
      if (value == 1) {
        this.infoNote(`Vous avez activé ${userName}`);
      } else {
        this.errorNote(`Vous avez désactivé ${userName}`);
      }
    },

    successNote(message) {
      toastr.success(message);
    },
    infoNote(message) {
      toastr.info(message);
    },
    errorNote(message) {
      toastr.error(message);
    },
    warningNote(message) {
      toastr.warning(message);
    },

    passwordInput() {
      this.changePass = !this.changePass;
    },
    profileInput() {
      this.changeProfile = !this.changeProfile;
    },
  },
};
