export default {
  authentification: {
    login: "login",
    register: "register",
    logout: "logout",
  },

  categories: {
    index: "categories",
    show: "show-category",
    add: "new-category",
    update: "update-category",
    delete: "delete-category",
  },

  products: {
    index: "products",
    show: "show-product",
    add: "new-product",
    update: "update-product",
    delete: "delete-product",
  },
};
