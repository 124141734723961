AXIOS.defaults.withCredentials = true;
import Cookies from "js-cookie";
import ENDPOINTS from "../../../components/utils/ENDPOINTS";
import AXIOS from "@/components/config/AXIOS";
import { errorNote } from "../../../components/utils/utils";

const state = {
  isAuthenticated: false,
  user: null,
  role: null,
  lastActivity: null,
  loginError: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  userAuth: (state) => state.user,
  getRole: (state) => state.role,
  lastActivity: (state) => state.lastActivity,
};

const actions = {
  async register({ commit }, newUser) {
    try {
      const response = await AXIOS.post(
        ENDPOINTS.authentification.register,
        newUser
      );
      commit("REGISTER", response.data);
    } catch (error) {
      if (error.response) {
        errorNote(error.response.data.message);
      } else if (error.request) {
        errorNote(error.request);
      } else {
        errorNote(error.message);
      }
    }
  },

  async login({ commit, dispatch }, user) {
    try {
      const response = await AXIOS.post(ENDPOINTS.authentification.login, user);
      user = response.data.user;
      state.role = user.role;
      commit("SET_USER", user);
      commit("SET_AUTHENTICATED", true);
      dispatch("resetInactivityTimer");
    } catch (error) {
      commit("LOGIN_FAILURE", error);
      if (error.response) {
        errorNote(error.response.data.message);
      } else if (error.request) {
        errorNote(error.request);
      } else {
        errorNote(error.message);
      }
    }
  },

  async logout({ commit }) {
    try {
      await AXIOS.post(ENDPOINTS.authentification.logout);

      commit("LOGOUT");
      commit("SET_AUTHENTICATED", false);
      commit("SET_LAST_ACTIVITY", null);
    } catch (error) {
      if (error.response) {
        errorNote(error.response.data.message);
      } else if (error.request) {
        errorNote(error.request);
      } else {
        errorNote(error.message);
      }
    }
  },

  resetInactivityTimer({ commit }) {
    commit("SET_LAST_ACTIVITY", Date.now());
  },

  checkInactivity({ state, dispatch }) {
    const currentTime = Date.now();
    const idleTime = 5 * 60 * 1000;
    if (
      state.isAuthenticated &&
      state.lastActivity &&
      currentTime - state.lastActivity > idleTime
    ) {
      dispatch("logout");
    } else {
      dispatch("resetInactivityTimer");
    }
  },
};

const mutations = {
  REGISTER: (state, newUser) => {
    state.users.unshift(newUser);
  },

  SET_USER(state, user) {
    state.user = user;
    state.isAuthenticated = true;
  },
  LOGOUT(state) {
    state.user = null;
    state.role = null;
    Cookies.remove("auth_token");
  },
  SET_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  SET_LAST_ACTIVITY(state, lastActivity) {
    state.lastActivity = lastActivity;
  },
  LOGIN_FAILURE(state, errorMessage) {
    state.loginError = errorMessage;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
