<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <!-- BEGIN  -->
            <div class="col-md-12">
              <div class="row"></div>
              <!-- TABLE: LATEST ORDERS -->
              <div class="card">
                <div class="card-header border-transparent">
                  <div class="col-md-3">
                    <h3 class="card-title">
                      Users
                      <span class="badge badge-warning right">
                        {{ usersFilterByName.length }}
                      </span>
                    </h3>
                  </div>
                  <div class="card-tools">
                    <div
                      class="input-group input-group-sm"
                      style="width: 150px"
                    >
                      <input
                        type="text"
                        v-model="q"
                        class="form-control float-right"
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-default">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="card-tools"></div>
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table m-0">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" v-model="selectAllUsers" />
                          </th>
                          <th style="width: 10px">#</th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Phone</th>
                          <th>Created at</th>
                          <th style="width: 20px">
                            <i class="fa fa-gear"></i>
                            ACTION
                          </th>

                          <!-- <th v-show="userRole != 1">...By</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(user, i) in usersFilterByName" :key="i">
                          <td>
                            <input
                              type="checkbox"
                              :value="user.id"
                              v-model="checked"
                            />
                          </td>
                          <td>{{ i + 1 }}</td>
                          <th>
                            <div class="image user-p">
                              <img
                                :src="`${imgUrl + '/' + user.profile}`"
                                class="img-circle elevation-2 user-p"
                                alt=""
                              />
                            </div>
                          </th>
                          <td>{{ user.name }}</td>
                          <td>{{ user.email }}</td>

                          <td v-if="user.role == 2" class="text-secondary">
                            simple
                          </td>
                          <td
                            v-if="user.role == 1"
                            class="text-warning"
                            style="width: 10px"
                          >
                            admin
                          </td>
                          <td
                            v-if="user.role == 3"
                            class="text-success"
                            style="width: 10px"
                          >
                            super
                          </td>

                          <td>
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'statusSwitch' + user.id"
                                :checked="user.status === 1"
                                @change="toggleUserStatus(user)"
                              />
                              <label
                                class="custom-control-label"
                                :for="'statusSwitch' + user.id"
                              >
                                {{ user.status === 1 ? "Actif" : "Inactif" }}
                              </label>
                            </div>
                          </td>

                          <td>{{ user.phone }}</td>
                          <td>
                            {{ formatDate(user.created_at) }}
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-light dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Choose
                              </button>
                              <div
                                class="dropdown-menu"
                                x-placement="bottom-start"
                                style="
                                  position: absolute;
                                  transform: translate3d(0px, 38px, 0px);
                                  top: 0px;
                                  left: 0px;
                                  will-change: transform;
                                "
                              >
                                <button
                                  @click="deleteUser(user.id)"
                                  type="button"
                                  class="dropdown-item"
                                >
                                  <i class="fas fa-trash"></i>
                                  Delete
                                </button>
                                <router-link
                                  class="dropdown-item nav-link nav-item btn"
                                  :to="{
                                    name: 'UpdateUser',
                                    params: { id: user.id },
                                  }"
                                >
                                  <i class="fas fa-edit"></i>
                                  Edit
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="pagination justify-content-center">
                  <el-pagination
                    background
                    :total="usersList.length"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    v-model:currentPage="currentPage"
                  />
                </div>

                <div class="card-footer clearfix row">
                  <!-- <div class="col-md'4">
                    <router-link
                      to="/new-user"
                      class="btn btn-light btn-app float-left"
                      ><i class="fas fa-plus"></i
                    ></router-link>
                  </div> -->
                  <div class="col-md-8">
                    <div class="">
                      <div
                        class="float-right row"
                        v-show="usersList.length > 0"
                      >
                        <div class="form-group col-md-4">
                          <label>Filter By Role</label>
                          <select class="custom-select" v-model="role_filter">
                            <option></option>
                            <option class="input" selected :value="1">
                              admin
                            </option>
                            <option class="input" selected :value="2">
                              simple
                            </option>
                            <option class="input" selected :value="3">
                              super
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Filter By Status</label>
                          <select class="custom-select" v-model="status_filter">
                            <option></option>
                            <option class="input" selected :value="1">
                              active
                            </option>
                            <option class="input" selected :value="2">
                              inactive
                            </option>
                          </select>
                        </div>
                        <div class="">
                          <button
                            @click="deleteUsers"
                            v-if="checked.length > 0"
                            class="btn btn-app btn-danger btn-sm"
                          >
                            <i class="fas fa-trash"></i>
                            DELETE
                            <span class="badge badge-secondary">{{
                              checked.length
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END -->
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>
        
        <script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import auth from "@/components/mixins/globals/auth/auth";

export default {
  data() {
    return {};
  },

  mixins: [template, usersMixin, auth],
  methods: {},

  mounted() {},

  computed: {},
};
</script>
    
        