import axios from "axios";
import { prodURL } from "./CONSTANTS";
import { getLocalItem, removeLocalItem } from "../utils/utils";

const AXIOS = axios.create({
  baseURL: prodURL,
  withCredentials: true,
});

AXIOS.interceptors.request.use(
  (config) => {
    const token = getLocalItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeLocalItem("access_token");
    }
    return Promise.reject(error);
  }
);

export default AXIOS;
