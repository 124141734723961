import {
  fetchData,
  createData,
  updateDataWithImage,
  deleteData,
} from "@/components/utils/actions";
import {
  FETCH_DATA,
  NEW_DATA,
  UPDATE_DATA,
  DELETE_DATA,
} from "@/components/utils/mutations";
import ENDPOINTS from "../../../components/utils/ENDPOINTS";

const state = {
  categories: [],
};

const getters = {
  getCategories: (state) => state.categories,
};

const actions = {
  async fetchCategories({ commit }) {
    await fetchData(ENDPOINTS.categories.index, commit, "FETCH_CATEGORIES");
  },

  async newCategorie({ commit }, category) {
    const response = await createData(
      ENDPOINTS.categories.add,
      category,
      commit,
      "NEW_CATEGORIE"
    );
    return response;
  },

  async updateCategorie({ commit }, category) {
    const response = await updateDataWithImage(
      ENDPOINTS.categories.update,
      category.id,
      category,
      {
        name: category.name,
        image: category.image,
      }
    );
    return response;
  },

  async deleteCategorie({ commit }, id) {
    const response = await deleteData(
      "delete-category",
      id,
      commit,
      "DELETE_CATEGORIE"
    );
    return response;
  },
};

const mutations = {
  FETCH_CATEGORIES(state, categorie) {
    FETCH_DATA(state, categorie, "categories");
  },

  NEW_CATEGORIE: (state, categorie) => {
    NEW_DATA(state, categorie, "categories");
  },
  UPDATE_CATEGORIE: (state, updated) => {
    UPDATE_DATA(state, "categorie", "categories", updated);
  },

  DELETE_CATEGORIE: (state, id) => {
    DELETE_DATA(state, id, "categories");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
