import exportMixin from "@/components/mixins/globals/exports/export";
import AXIOS from "@/components/config/AXIOS";
export default {
  mixins: [exportMixin],

  watch: {
    selectAllUsers: function (value) {
      if (value) {
        this.usersFilterByName.forEach((user) => {
          this.checked.push(user.id);
        });
      } else {
        this.checked = [];
      }
    },
  },

  methods: {
    addUser() {
      let formData = new FormData();
      formData.append("name", this.user.name);
      formData.append("role", this.user.role);
      formData.append("phone", this.user.phone);
      formData.append("email", this.user.email);
      formData.append("profile", this.image);
      formData.append("password", this.user.password);

      this.newData(this.newUser, formData, "utilisateur créé");
    },

    editUser() {
      let formData = new FormData();
      formData.append("name", this.user.name);
      formData.append("role", this.user.role);
      formData.append("phone", this.user.phone);
      formData.append("email", this.user.email);
      formData.append("profile", this.image);
      formData.append("password", this.user.password);

      formData = this.user;
      this.updateData(this.updateUser, formData, "utilisateur mis à jour");
    },

    loadData(url) {
      AXIOS.get(url).then((response) => {
        this.user = response.data;
        this.user.profile = response.data.profile;
      });
    },

    async deleteUsers() {
      await AXIOS.delete("delete-all-users/" + this.checked);
      await this.fetchUsers();

      this.checked = "";
      this.selectAllUsers = "";
    },

    updateUserStatus(userId, newStatus) {
      if (newStatus !== 1 && newStatus !== 2) {
        console.error("Valeur de statut invalide");
        return;
      }

      AXIOS.put(`/users/${userId}/status`, { status: newStatus })
        .then((response) => {
          if (response && response.data && response.data.message) {
            this.fetchUsers();
            this.statusChange(newStatus, response.data.username);
            console.log(response.data.username);
          } else {
            console.error(
              "Réponse invalide de la mise à jour du statut de l'utilisateur"
            );
          }
        })
        .catch((error) => {
          console.error(
            "Échec de la mise à jour du statut de l'utilisateur :",
            error
          );
        });
    },

    toggleUserStatus(user) {
      const newStatus = user.status === 1 ? 2 : 1;
      this.updateUserStatus(user.id, newStatus);
    },
  },

  computed: {
    usersList() {
      return this.fetchData(this.getUsers);
    },

    lastRegistered() {
      return this.fetchLast(this.usersList, "2");
    },

    adminList() {
      return this.fetchDataByAttributeCompare(this.usersList, "role", "=", 1);
    },

    simpleList() {
      return this.fetchDataByAttributeCompare(this.usersList, "role", "=", 2);
    },

    activeUsers() {
      return this.fetchDataByAttributeCompare(this.usersList, "status", "=", 1);
    },

    inactiveUsers() {
      return this.fetchDataByAttributeCompare(this.usersList, "status", "=", 2);
    },

    usersFilterByRole() {
      return this.fetchDataByQueryOnAttribute(
        this.role_filter,
        this.usersList,
        "role"
      );
    },

    usersFilterByStatus() {
      return this.fetchDataByQueryOnAttribute(
        this.status_filter,
        this.usersFilterByRole,
        "status"
      );
    },

    usersFilterByName() {
      return this.fetchDataByQueryOnName(
        this.q,
        this.usersFilterByStatus
      ).slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
};
