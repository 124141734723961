<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            v-if="isLoggedIn"
            :src="`${imgUrl + '/' + userAuth.profile}`"
            class="img-circle elevation-2"
            alt="img"
          />
        </div>
        <div class="info">
          <div href="#" class="d-block" v-if="userAuth">
            <router-link
              class=""
              :to="{
                name: 'UpdateAuth',
                params: { id: userAuth.id },
              }"
            >
              <p class="text mx-auto">
                {{ userAuth.name }}
                <span v-if="authRole == 1" class="badge badge-warning"
                  >admin</span
                >

                <span v-if="authRole == 3" class="badge badge-success"
                  >super admin</span
                >
              </p>
            </router-link>
            <div class="">
              <ul>
                <a v-if="isLoggedIn">
                  <button @click="logOut" class="btn btn-danger" data-tilt>
                    <i class="fas fa-power-off"></i><span></span>
                  </button>
                </a>
                <!-- <router-link to="/" class="btn btn-light ml-3"
                  ><i class="fas fa-home"></i><span></span
                ></router-link> -->
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link bg-warning">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              Tableau de bord
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/categories" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              Categories
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/products" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              Articles
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/orders" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              Commandes
            </router-link>
          </li>
          <hr />
          <li class="nav-item">
            <router-link to="/pubs" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              Publicités
            </router-link>
          </li>
          <li class="nav-header">Autres</li>
          <li class="nav-item">
            <router-link to="/users" class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              Utilisateurs
            </router-link>
          </li>
          <li class="nav-item">
            <a href="" class="nav-link bg-blue">
              <i class="nav-icon fas fa-chart-line"></i>
              <p>
                Graphiques
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right"></span>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/chart-user" class="nav-link">
                  <i class="fas fa-chart-line nav-icon"></i>
                  Utilisateurs
                </router-link>
              </li>
            </ul>
          </li>
          <div class="nav-item">
            <label class="mr-3">{{ $t("langageLabel") }} : </label>
            <select
              v-model="$i18n.locale"
              class="language-selector form-select-lg rounded shadow"
              aria-label="Default select example"
            >
              <option value="en">English</option>
              <option value="fr">Français</option>
            </select>
          </div>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import auth from "@/components/mixins/globals/auth/auth";
export default {
  data() {
    return {};
  },

  mixins: [auth],
  methods: {},

  mounted() {},

  computed: {},
};
</script>
