<template>
  <div>
    <horizontal />
    <vertical />

    <div class="component">
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Tableau de bord</h1>
              </div>
            </div>
          </div>
        </div>

        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <InfoBox
                title="UTILISATEURS"
                :count="usersList.length"
                bgColor="info"
                :icon="icons.user"
              />
              <InfoBox
                title="ADMINISTRATEURS"
                :count="adminList.length"
                bgColor="info"
                :icon="icons.user"
              />
              <InfoBox
                title="VENDEURS"
                :count="simpleList.length"
                bgColor="info"
                :icon="icons.user"
              />
              <InfoBox
                title="UTILISATEURS ACTIFS"
                :count="activeUsers.length"
                bgColor="success"
                :icon="icons.user"
              />
              <InfoBox
                title="UTILISATEURS INACTIFS"
                :count="inactiveUsers.length"
                bgColor="danger"
                :icon="icons.user"
              />
              <InfoBox
                title="ARTICLES"
                :count="productsList?.length"
                bgColor="secondary"
                :icon="icons.book"
              />
              <div class="clearfix hidden-md-up"></div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="row"></div>
                <!-- TABLE: LATEST ORDERS -->
                <div class="card shadow-lg">
                  <div class="card-header border-transparent">
                    <div class="col-md-3">
                      <h3 class="card-title">
                        <span class="badge badge-warning right">
                          {{ lastRegistered.length }}
                        </span>
                        Dernieres inscriptions
                      </h3>
                    </div>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th style="width: 10px">#</th>
                            <th>Profil</th>
                            <th>Nom</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Statut</th>
                            <th>Téléphone</th>
                            <th>Date d'inscription</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(user, i) in lastRegistered" :key="i">
                            <td>{{ i + 1 }}</td>
                            <th>
                              <div class="image user-p">
                                <img
                                  :src="`${imgUrl + '/' + user.profile}`"
                                  class="img-circle elevation-2 user-p"
                                  alt=""
                                />
                              </div>
                            </th>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>

                            <td v-if="user.role != 1" class="text-light">
                              simple
                            </td>
                            <td
                              v-if="user.role == 1"
                              class="text-warning"
                              style="width: 10px"
                            >
                              admin
                            </td>

                            <td>
                              <div class="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="'statusSwitch' + user.id"
                                  :checked="user.status === 1"
                                  @change="toggleUserStatus(user)"
                                />
                                <label
                                  class="custom-control-label"
                                  :for="'statusSwitch' + user.id"
                                >
                                  {{ user.status === 1 ? "Actif" : "Inactif" }}
                                </label>
                              </div>
                            </td>

                            <td>{{ user.phone }}</td>
                            <td>
                              {{ formatDate(user.created_at) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row">
                  <div class="info-box mb-3 bg-warning col-md-12 mr-0">
                    <span class="info-box-icon"
                      ><i :class="icons.pending"></i
                    ></span>

                    <div class="info-box-content">
                      <span class="info-box-text">Commandes en attente</span>
                      <span class="info-box-number"
                        >{{ waitingOrders.length }} (Total :
                        {{ formatPrice(sumTotalWaiting) }})</span
                      >
                    </div>
                  </div>
                  <div class="info-box mb-3 bg-success col-md-12 mr-0">
                    <span class="info-box-icon"
                      ><i :class="icons.validated"></i
                    ></span>

                    <div class="info-box-content">
                      <span class="info-box-text">Commandes validées</span>
                      <span class="info-box-number"
                        >{{ validatedOrders.length }} (Total :
                        {{ formatPrice(sumTotalValidated) }})</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import template from "@/components/mixins/globals/template";
import usersMixin from "@/components/mixins/models/users";
import products from "@/components/mixins/models/products";
import orders from "@/components/mixins/models/orders";
import InfoBox from "../../../../ui/InfoBox.vue";

export default {
  mixins: [template, usersMixin, orders, products],

  components: {
    InfoBox,
  },
};
</script>

<style></style>
